<template>
	<div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader
            type="paragraph"
            class="mt-3"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <v-col cols="11" class="pa-0 mt-10 py-10" v-else-if="items.length < 1 && !filtering">
			<v-row>
				<v-col cols="12" sm="6">
					<img src="@/assets/images/405.png" width="100%"/>
				</v-col>
				<v-col cols="12" sm="6" class="text-black flex flex-wrap content-center">
					<v-col cols="12" class="text-3xl font-bold pl-0">{{bahasa? 'Data tidak ditemukan' : 'No data available.'}}</v-col>
					<v-btn class="btn-primary mt-8" :to="{name: 'home'}">
						{{bahasa ? 'kembali ke beranda' : 'back to home'}}
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
    <template v-else>
      <div class="white grid justify-items-center px-0 py-10 text-left">
        <v-col cols="10" sm="6" class="pa-0 mt-10 pt-10">
          <div class="font-bold text-2xl sm:text-4xl mt-5 text-capitalize" v-if="bahasa">Program</div>
          <div class="font-bold text-2xl sm:text-4xl mt-5 text-capitalize" v-else>Programs</div>
        </v-col>
      </div>
      <div class="grid justify-items-center py-0">
        <v-col cols="12" sm="6" class="text-right px-5">
          <v-btn right outlined v-if="!isFiltered" @click="isFiltered = !isFiltered" color="green darken-1">Filter <v-icon>filter_alt</v-icon></v-btn>
          <v-btn right outlined v-else @click="isFiltered = !isFiltered" color="green darken-1">Filter <v-icon>filter_alt</v-icon></v-btn>
        </v-col>
      </div>
      <div class="grid justify-items-center py-10 grey lighten-4" v-if="isFiltered">
        <v-col cols="10" sm="6">
          <v-row class="justify-items-center">
            <v-col cols="12" sm="4">
              <v-menu
                width="100%"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    depressed
                    class="btn-medium text-capitalize text-left"
                    color=""
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    Filter {{ bahasa ? 'Layanan' : 'Service' }}
                    <v-icon class="mr-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-card width="100%" class="mx-auto">
                  <v-list>
                    <v-list-item-group v-model="filter.service" multiple>
                      <template v-for="(item, i) in services">
                        <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                        <v-list-item v-else :key="`item-${i}`" :value="item">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="green darken-1"
                                item-color="green darken-1"
                                :true-value="item"
                                readonly
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content class="text-left">
                              <v-list-item-title v-text="item.name" class="text-left"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                width="100%"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    depressed
                    class="btn-medium text-capitalize text-left"
                    color=""
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    Filter {{ bahasa ? 'Sektor' : 'Sector' }}
                    <v-icon class="mr-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-card width="100%" class="mx-auto">
                  <v-list>
                    <v-list-item-group v-model="filter.sector" multiple>
                      <template v-for="(item, i) in sectors">
                        <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                        <v-list-item v-else :key="`item-${i}`" :value="item">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="green darken-1"
                                item-color="green darken-1"
                                :true-value="item"
                                readonly
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.name" class="text-left"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                width="100%"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    depressed
                    class="btn-medium text-capitalize text-left"
                    color=""
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    Filter Window
                    <v-icon class="mr-1">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-card width="100%" class="mx-auto">
                  <v-list>
                    <v-list-item-group v-model="filter.windows" multiple>
                      <template v-for="(item, i) in windows">
                        <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                        <v-list-item v-else :key="`item-${i}`" :value="item">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="green darken-1"
                                item-color="green darken-1"
                                :true-value="item"
                                readonly
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.name" class="text-left"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <v-row class="justify-content-center py-10" v-if="filter.sector.length > 0 || filter.service.length > 0 || filter.windows.length > 0">
        <v-col cols="11" sm="6">
          <v-row>
            <v-col cols="12" sm="3" class="text-left">
              <span class="text-2xl font-bold">Filter By</span>
            </v-col>
            <v-col cols="8" sm="9" class="px-0 text-left pt-1">
              <v-chip
                v-for="(service, idxService) in filter.service"
                :key="'service' + idxService"
                class="ma-2"
                close
                color="green"
                label
                outlined
                @click:close="removeFilter('service', idxService)"
              >
                {{ service.name }}
              </v-chip>
              <v-chip
                v-for="(sector, idxSector) in filter.sector"
                :key="'sector' + idxSector"
                class="ma-2"
                close
                color="green"
                label
                outlined
                @click:close="removeFilter('sector', idxSector)"
              >
                {{ sector.name }}
              </v-chip>
              <v-chip
                v-for="(window, idxWindow) in filter.windows"
                :key="'window' + idxWindow"
                class="ma-2"
                close
                color="green"
                label
                outlined
                @click:close="removeFilter('windows', idxWindow)"
              >
                {{ window.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row class="justify-items-center px-3 pt-3">
            <v-col cols="12" class="text-right">
              <v-btn color="green darken-4" @click="resetFilter()" outlined class="mb-2">
                Reset filter
              </v-btn>
              <v-btn color="green darken-4" dark @click="getProgram()" class="ml-5">
                {{bahasa ? 'Terapkan filter' : 'Apply filter'}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Program -->
      <div v-if="isLoad" class="my-3">
        Loading...
      </div>
      <div v-else class="pb-5 white">
        <v-col cols="12" sm="6" class="sm:block m-auto px-0">
          <div class="cursor-pointer mb-3 text-left">
            <v-row v-if="items.length > 0">
              <v-col cols="12" sm="4" v-for="(item, index) in items" :key="index" @click="goTo(item)">
                <v-row class="px-6 sm:px-0">
                  <v-col cols="auto" class="pt-4">
                    <img src="@/assets/images/icon_program.svg" width="80%"/>
                  </v-col>
                  <v-col cols="9" class="px-0">
                    <div class="font-bold text-xl py-0">{{ item.name }}</div>
                    <div class="text-sm py-3">{{ item.createdDate }}</div>
                    <!-- <div class="font-lora text-base">{{ item.service }}</div> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="px-5">
                <span v-if="isLoad">Loading...</span>
                <span v-else-if="filtering">{{ bahasa ? 'Data tidak ditemukan.' : 'Data not found.' }}</span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </div>
    </template>
	</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
	data() {
		return {
			slickOptions: {
        slidesToShow: 2,
        prevArrow: '.nextProgram-btn',
        nextArrow: '.backProgram-btn'
      },
      slickOptionsXS: {
        slidesToShow: 1,
        prevArrow: '.nextProgram-btn-xs',
        nextArrow: '.backProgram-btn-xs'
      },
      items: [],
			baseUrl: process.env.VUE_APP_BASE_URL,
			isFiltered: false,
      isLoad: false,
      filter: {
        service: [],
        sector: [],
        windows: []
      },
      services: [],
      sectors: [],
      windows: []
		}
	},
	props: {
		bahasa: {
			type: Boolean,
			default: true
		}
	},
  mounted() {
    this.getData();
  },
  watch: {
    filter: {
      handler(val) {
        if (val.sector.length < 1 && val.service.length < 1 && val.windows.length < 1) {
          this.getData();
        }
      },
      deep: true
    },
    bahasa() {
      this.getData();
    }
  },
	computed: {
    language() {
      return this.bahasa ? 'ID' : 'ENG';
    },
    filtering() {
      return this.filter.service.length > 0 || this.filter.sector.length > 0 || this.filter.windows.length > 0;
    }
	},
  methods: {
    getData() {
      this.getProgram();
      this.getService();
      this.getSector();
      this.getWindows();
    },
    async getProgram() {
      this.isLoad = true;
			let param = {
				lang: this.language,
        service: this.filter.service.map(item => item.id),
        sector: this.filter.sector.map(item => item.id),
        donor: this.filter.windows.map(item => item.id)
			}
      await axios.get(this.baseUrl + '/program', {params: param}).then(response => {
        if (response.data.code === 200) {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.pageCount = response.data.data.last_page;
        }
        this.isLoad = false;
      })
      .catch(error => {
				Swal.fire('Failed!', error.response.data.message, 'error');
      })
      .finally(() => this.isLoad = false);
		},
    async getService() {
			let param = {
				lang: this.language
			}
      await axios.get(this.baseUrl + '/list/service', {params: param}).then(response => {
        if (response.data.code === 200) {
          this.services = response.data.data;
        }
      })
      .catch(error => {
				Swal.fire('Failed!', error.response.data.message, 'error');
      })
		},
    async getSector() {
			let param = {
				lang: this.language
			}
      await axios.get(this.baseUrl + '/list/sector', {params: param}).then(response => {
        if (response.data.code === 200) {
          this.sectors = response.data.data;
        }
      })
      .catch(error => {
				Swal.fire('Failed!', error.response.data.message, 'error');
      })
		},
    async getWindows() {
			let param = {
				lang: this.language
			}
      await axios.get(this.baseUrl + '/list/donor', {params: param}).then(response => {
        if (response.data.code === 200) {
          this.windows = response.data.data;
        }
      })
      .catch(error => {
				Swal.fire('Failed!', error.response.data.message, 'error');
      })
		},
    goTo(val) {
      this.$router.push({
        name: 'program-detail',
				params: {
					id: val.id
				}
      });
    },
    removeFilter(key, index) {
      this.filter[key].splice(index, 1);
    },
    resetFilter() {
      this.filter = {
        service: [],
        sector: [],
        windows: []
      };
      this.getProgram();
    }
   }
}
</script>